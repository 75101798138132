import React from "react";
import logo from "../../static/images/logo.png";
import {Link} from "gatsby"
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

import * as styles from './navigation.module.css'

export default function Navigation() {
  return (
    <Navbar bg="light" expand="lg">
      <Container>
        <Navbar.Brand className="d-flex justify-content-start" href="/">
          <img alt="logo" src={logo} className={styles.logo}/>
        </Navbar.Brand>
        <Navbar.Toggle className="w-100" aria-controls="basic-navbar-nav">
          <span class="navbar-toggler-icon"></span>
        </Navbar.Toggle>

        <Navbar.Collapse className="w-100" id="basic-navbar-nav">
          <Nav className="navbar-nav ml-auto w-100 d-flex justify-content-end">
            <div className="eventss"  > <NavDropdown >
              <NavDropdown.Item href="/events/#speakers" className={styles.navigationItemLink}>Speakers</NavDropdown.Item>
              <NavDropdown.Item href="/events/#hosts" className={styles.navigationItemLink}>Hosts</NavDropdown.Item>
              <NavDropdown.Item href="/events/#performers" className={styles.navigationItemLink}>Performers</NavDropdown.Item>
            </NavDropdown>
            <Link to="/events/" activeClassName="activeLink" className={styles.navigationItemLink}>
              Events
            </Link>
            </div>
        
            <Link to="/sponsors/" activeClassName="activeLink" className={styles.navigationItemLink}>
              Sponsors
            </Link>
            <Link to="/videos/" activeClassName="activeLink" className={styles.navigationItemLink}>
              Videos
            </Link>
            <Link to="/speakers/" activeClassName="activeLink" className={styles.navigationItemLink}>
              Speakers
            </Link>
            <Link to="/team/" activeClassName="activeLink" className={styles.navigationItemLink}>
              The Team
            </Link>
            <Link to="/about/" activeClassName="activeLink" className={styles.navigationItemLink}>
              About
            </Link>
            <Link to="/get-involved/" activeClassName="activeLink" className={styles.navigationItemLink}>
              Get Involved
            </Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
};
